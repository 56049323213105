<template>
  <v-dialog v-model="dialog" persistent scrollable width="750">
    <v-card>
      <v-card-title class="py-2">Crear Comité Personal</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5 mb-2">
        <div class="px-2">
          <v-row>
            <v-col cols="12" sm="12" class="py-0 d-flex">
              <v-text-field
                v-model="asunto"
                class="mr-3"
                dense
                background-color="transparent"
                placeholder="Agregar Asunto"
              ></v-text-field>
              <v-menu v-model="menu" top nudge-bottom="174" nudge-left="16" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="color">
                    fas fa-circle
                  </v-icon>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="color" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="4" class="py-0">
              <v-menu
                v-model="menuDateInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="
                      fechaInicio
                        .split('-')
                        .reverse()
                        .join('-')
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  @input="menuDateInicio = false"
                  locale="es-es"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3" lg="3" class="py-0" v-if="!todoDia">
              <v-menu
                ref="menuHoraInicio"
                v-model="menuHoraInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaInicio"
                    dense
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuHoraInicio"
                  v-model="horaInicio"
                  full-width
                  format="ampm"
                  @click:minute="$refs.menuHoraInicio.save(horaInicio)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3" lg="3" class="py-0" v-if="!todoDia">
              <v-menu
                ref="menuHoraFin"
                v-model="menuHoraFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaFin"
                    dense
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuHoraFin"
                  v-model="horaFin"
                  :min="horaInicio"
                  full-width
                  format="ampm"
                  @click:minute="$refs.menuHoraFin.save(horaFin)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <span class=" d-flex align-center font-weight-bold" md="2" v-if="todoDia"> - </span>
            <v-col cols="12" sm="5" lg="4" md="5" v-if="todoDia" class="py-0">
              <v-menu
                v-model="menuDateFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="
                      fechaFin
                        .split('-')
                        .reverse()
                        .join('-')
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  :min="fechaInicio"
                  @input="menuDateFin = false"
                  locale="es-es"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="4" md="3" lg="3" class="d-flex align-center py-0">
              <v-checkbox v-model="todoDia" dense label="Todo el día"></v-checkbox>
            </v-col>
            <v-col cols="6" sm="5" md="4" lg="4" class="py-0">
              <v-select v-model="seleccion" dense :items="items" background-color="transparent" class="pa-0"></v-select>
            </v-col>
          </v-row>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="px-2">
          <v-row>
            <v-col cols="12" sm="12" class="pb-2 pt-5">
              <h3>Detalles del Evento</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center py-0">
              <v-text-field prepend-icon="fas fa-calendar" :value="usuario" dense readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" class="py-0">
              <v-data-table
                dense
                :headers="cInvitado"
                :items="invitados"
                :items-per-page="-1"
                class="border"
                hide-default-footer
                multi-sort
                :mobile-breakpoint="0"
                no-data-text="No se encontraron datos"
                no-results-text="No se encontraron datos"
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2">
                    <div class="d-flex align-center white--text body-1">
                      <span class="text-decoration-underline">I</span>NVITADOS
                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    </div>
                    <div class="d-flex align-center">
                      <v-btn
                        color="white"
                        fab
                        icon
                        x-small
                        @click="addRowsInvitado"
                        v-shortkey="['ctrl', 'i']"
                        @shortkey="addRowsInvitado"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.dni`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.dni">
                    {{ item.dni }}
                    <template v-slot:input>
                      <v-text-field v-model="item.dni" label="Dni" single-line type="number"></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.object" @save="saveName(item.id, item.object, item.new)">
                    {{ item.name }}
                    <template v-slot:input>
                      <div v-if="!item.new" class="d-flex align-baseline">
                        <v-autocomplete
                          v-model="item.object"
                          :search-input.sync="search"
                          :items="responsables"
                          item-text="name"
                          item-value="code"
                          label="Nombres"
                          placeholder="Nombres"
                          prepend-icon="mdi-database-search"
                          hide-no-data
                          hide-selected
                          return-object
                        >
                          <template v-slot:selection="data">
                            {{ data.item.name }}
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}`
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                        <v-btn icon color="primary" @click="changeNewEditText(item.id)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                      <v-text-field v-else v-model="item.name" label="Nombres" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <v-edit-dialog @save="saveEmail(item.id, item.email)">
                    {{ item.email }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.email"
                        label="Email"
                        :suffix="item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com'"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-icon small @click="deleteItemEquipo(item.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small @click="closePopup()">Cancelar</v-btn>
        <v-btn color="primary" small submit @click="guardarEvento(editar[0].editar)">
          {{ editar[0].editar ? 'Editar' : 'Guardar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import EventoService from '../services/EventoService';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { notificateEvento } from '@/services/notificationService';
import { decryptAES } from '@/components4x/utils/utils4x';

export default {
  name: 'EventoFormulario',
  data: () => ({
    showLoading: false,
    eventoService: null,
    search: '',
    menu2: false,
    usuario: '',
    menuDateInicio: false,
    menuDateFin: false,
    menuHoraInicio: false,
    horaInicio: null,
    menuHoraFin: false,
    horaFin: null,
    fechaInicio: '',
    fechaFin: '',
    minuto: 10,
    todoDia: false,
    color: '#1976D2FF',
    menu: false,
    ubicacion: '',
    asunto: '',
    tiempoSeleccionado: 'MIN',
    tiempos: [
      { text: 'MINUTOS', value: 'MIN' },
      { text: 'HORAS', value: 'H' },
      { text: 'DIAS', value: 'D' },
      { text: 'SEMANAS', value: 'S' }
    ],
    cInvitado: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'DNI',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Acciones', value: 'acciones' }
    ],
    responsables: [],
    invitados: [],
    items: [],
    seleccion: 'NO'
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    editar: {
      type: Array,
      default: () => [{ editar: false }]
    }
  },
  watch: {
    todoDia(val) {
      if (val) {
        this.horaInicio = '';
      }
    },
    async editar(val) {
      this.asunto = val[0].asunto;
      this.fechaInicio = val[0].inicio;
      this.fechaFin = val[0].todoDia ? val[0].fin : '';
      this.horaInicio = val[0].todoDia ? '' : val[0].horaInicio;
      this.horaFin = val[0].todoDia ? '' : val[0].horaFin;
      this.todoDia = val[0].todoDia;
      this.seleccion = val[0].seleccion;
      this.color = val[0].color;
      this.minuto = val[0].minuto;
      this.tiempoSeleccionado = val[0].tiempoSeleccionado;
      console.log(val[0].idlider);
      await this.buscaUsuario(val[0].idlider);
      if (val[0].invitados != null) {
        val[0].invitados.forEach((el) => {
          this.invitados.push({
            id: uuidv4(),
            object: {
              code: el.idresponsable
            },
            dni: el.idtrabajador,
            name: el.nombres,
            email: el.email
          });
        });
      }
    },
    fechaInicio(val) {
      this.fechaFin = this.editar[0].editar ? this.editar[0].fin : val;
      const dia = new Date(val).getDate() + 1;
      const diaSelect = new Date(val).getDay();
      const mesSelect = new Date(val).getMonth();
      var dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'Domingo'];
      var meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre'
      ];
      this.items = [
        { text: 'No se repite', value: 'NO' },
        { text: 'Todos los días', value: 'TO' },
        { text: 'Cada semana, el ' + dias[diaSelect], value: 'CS' },
        { text: 'Todos los meses, el primer ' + dias[diaSelect], value: 'TM' },
        { text: 'Anualmente, el ' + dia + ' de ' + meses[mesSelect], value: 'AE' }
      ];
    },
    horaInicio(val) {
      this.horaFin = this.editar[0].editar ? this.editar[0].horaFin : val;
    },
    async dialog() {
      await this.buscaUsuario(decryptAES(localStorage.getItem('user')));
    }
  },
  methods: {
    clearDialog() {
      this.tiempoSeleccionado = 'M';
      this.invitados = [];
      this.fechaInicio = new Date().toISOString().substr(0, 10);
      this.seleccion = 'NO';
      this.asunto = '';
      this.ubicacion = '';
      this.todoDia = false;
      this.color = '#1976D2FF';
      this.horaInicio = null;
      this.horaFin = null;
      this.editar[0].editar = false;
    },
    openPopup() {
      this.$emit('open');
    },
    closePopup() {
      this.$emit('close');
    },
    clearRowInvitados(id) {
      const i = this.invitados.findIndex((el) => el.id === id);
      this.invitados.splice(i, 1);
    },
    saveName(id, object, inew) {
      if (inew) return;

      if (object === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const dni = object.dni;

      if (dni === '' || dni === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const res = this.responsables.filter((el) => el.dni === dni);
      this.invitados.map((el) => {
        if (el.id === id) {
          el.name = res[0].name;
          el.dni = res[0].dni;
          el.email = res[0].email;
        }
        return el;
      });
    },
    saveEmail(id) {
      this.invitados.map(
        (el) =>
          (el.email =
            el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
      );
    },
    changeNewEditText(id) {
      this.invitados.map((el) => (el.new = el.id === id ? true : el.new));
    },
    addRowsInvitado() {
      this.invitados.push({
        id: uuidv4(),
        dni: '',
        name: '',
        email: '',
        new: false,
        object: {}
      });
    },
    deleteItemEquipo(id) {
      console.log('entroooo');
      console.log(id);
      const i = this.invitados.findIndex((el) => el.id === id);
      this.invitados.splice(i, 1);
      console.log(this.invitados);
    },
    async cargarResponsables() {
      this.showLoading = true;
      const res = await this.eventoService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });

      if (res.length > 0) {
        res.forEach((el) => {
          this.responsables.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            name: el.nombresall,
            email: el.email,
            idsubarea: el.idsubarea,
            leader: false,
            new: false,
            object: {}
          });
        });
      } else {
        this.responsables = [];
      }

      this.showLoading = false;
    },
    async guardarEvento(aux) {
      const auxInvitados = [];
      let res;
      if (this.asunto.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un asunto');
        return;
      }
      if (this.horaInicio == null && this.todoDia == false) {
        await this.alertDialog('warning', 'Ingrese una hora');
        return;
      }
      let auxDni = false;
      if (this.invitados.length > 0) {
        this.invitados.forEach((el) => {
          if (el.dni == '' || el.nombre == '') {
            auxDni = true;
          }
        });
        if (auxDni) {
          this.alertDialog('warning', 'Falta dni/nombres de algunos invitados');
          return;
        }
      }

      this.invitados.forEach((el) => {
        auxInvitados.push({
          idresponsable: el.object.code != undefined ? el.object.code : '',
          idtrabajador: el.dni,
          nombres: el.name,
          email: el.email
        });
      });
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: `¿Está seguro de guardar los cambios?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        if (!aux) {
          res = await this.eventoService.post().execResource('guardar', {
            idempresa: decryptAES(localStorage.getItem('emp')),
            asunto: this.asunto,
            fecha1: this.fechaInicio,
            fecha2: this.todoDia ? this.fechaFin : '',
            hora1: this.todoDia ? '' : this.horaInicio,
            hora2: this.todoDia ? '' : this.horaFin,
            tododia: this.todoDia ? 1 : 0,
            repetir: this.seleccion,
            // ubicacion: this.ubicacion,
            alerta: '' /* this.minuto */,
            cadaalerta: '' /* this.tiempoSeleccionado */,
            idlider: '',
            color: this.color,
            icon: '',
            invitados: auxInvitados
          });
        } else {
          res = await this.eventoService.put().execResource('editar', {
            idevento: this.editar[0].idevento,
            idempresa: decryptAES(localStorage.getItem('emp')),
            asunto: this.asunto,
            fecha1: this.fechaInicio,
            fecha2: this.todoDia ? this.fechaFin : '',
            hora1: this.todoDia ? '' : this.horaInicio,
            hora2: this.todoDia ? '' : this.horaFin,
            tododia: this.todoDia ? 1 : 0,
            repetir: this.seleccion,
            // ubicacion: this.ubicacion,
            alerta: '' /* this.minuto */,
            cadaalerta: '' /* this.tiempoSeleccionado */,
            idlider: '',
            color: this.color,
            icon: '',
            invitados: auxInvitados
          });
        }
        this.showLoading = false;
        if (res.status) {
          if (!aux) {
            const invitados = [];
            let invitadosNoti = [];
            auxInvitados.forEach((el) => {
              invitados.push(el.idresponsable);
            });
            invitadosNoti = new Set(invitados);
            for (const participant of invitadosNoti) {
              await notificateEvento(participant, this.asunto);
            }
          }
          await this.alertDialog('success', res.data);
          this.closePopup();
        } else {
          await this.alertDialog('error', res.data);
        }
        this.$parent.cargarEventos();
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    buscaUsuario(usuario) {
      this.responsables.forEach((el) => {
        if (el.code == usuario) {
          this.usuario = el.name;
        }
      });
    }
  },
  mounted() {
    this.fechaInicio = new Date().toISOString().substr(0, 10);
  },
  async created() {
    this.eventoService = this.$httpService(new EventoService(), this);
    await this.cargarResponsables();
  }
};
</script>
